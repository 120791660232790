<template>
  <b-card>

    <b-card-title>
      {{ $t('Screens') }}
    </b-card-title>
    <b-card-text>
      <b-row class="d-flex align-items-center justify-content-between">
        <b-col md="12" v-if="isAdmin">
          <b-button size="sm" variant="info" @click="showLocationSetupModal()">
            {{ $t('Create New Location Setup') }}
          </b-button>
        </b-col>
        <b-col
          md="6"
          sm="4"
          class="my-1 mt-2"
        >
          <b-form-group class="mb-0">
            <div>
              <label class="d-inline-block text-sm-left mr-50">{{ $t('Per page') }}</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="my-1 mt-2"
        >
          <b-form-group
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Type to Search')"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  {{ $t('clear') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          md="3"
          sm="4"
          class="mb-2"
        >
          <label>{{ $t('Status') }}</label>
          <b-form-select
            id="perPageSelect"
            v-model="queryFilter.status"
            size="sm"
            :options="statusOptions"
            class="w-100"
          />
        </b-col>

        <b-col
          md="3"
          sm="4"
          class="mb-2"
        >
          <label>{{ $t('Location Id') }}</label>
          <b-form-input
            id="filterInput"
            v-model="queryFilter.locationId"
            type="text"
            size="sm"
            :placeholder="$t('Enter location ID')"
          />
        </b-col>

        <!-- <b-col md="2" sm="4" class="mb-2">
          <label>{{ $t('Max Allocation') }}</label>
          <b-form-input id="filterInput" v-model="queryFilter.maxAllowedAllocation" type="text" size="sm"
            :placeholder="$t('Enter max allocation')" />
        </b-col> -->

        <!-- <b-col md="2" sm="4" class="mb-2">
          <label>{{ $t('Size') }}</label>
          <b-form-input id="filterInput" v-model="queryFilter.screenSize" type="text" size="sm"
            :placeholder="$t('Enter Size')" />
        </b-col> -->

        <b-col
          cols="12"
          class="table-container"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            show-empty
            :busy="isLoading"
            :items="filteredSetups"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >

            <template #cell(locationId)="data">
              <b-badge
                class="font-weight-normal"
                variant="light-secondary"
              >
                <feather-icon
                  class="mr-5px"
                  icon="MapPinIcon"
                  size="14"
                />{{ data.item.locationId }}
              </b-badge>
            </template>

            <template #cell(price)="data">
              <b-badge
                class="font-weight-normal"
                variant="light-secondary"
              >
                {{ data.item.price }}
              </b-badge>
            </template>

            <template #cell(status)="data">
              <b-badge
                class="font-weight-normal"
                :variant="(data.item.status === 'ShowingAdx' || data.item.status === 'ShowingAdverts') ? 'success' : 'danger'"
              >
                {{ data.item.status }}
              </b-badge>
            </template>
            <template #cell(lastUpdate)="data">
              <b-badge
                class="font-weight-normal"
                variant="light-secondary"
              >
                {{ data.item.lastUpdate }}
              </b-badge>
            </template>
            <template #cell(lastPowerOn)="data">
              <b-badge
                class="font-weight-normal"
                variant="light-secondary"
              >
                {{ data.item.lastPowerOn }}
              </b-badge>
            </template>
            <template #cell(allowDefault)="data">
              <b-badge
                class="font-weight-normal"
                variant="light-secondary"
              >
                {{ data.item.allowDefault }}
              </b-badge>
            </template>
            <template #table-busy>
              <div class="text-center">
                <b-spinner label="Loading..." />
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex w-100">
                <div
                  class="w-50"
                  style="padding: 2px"
                >
                  <b-button
                    size="sm"
                    variant="dark"
                    class="w-100"
                    style="margin-top: 3px"
                    @click="showDetail(data.item)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </div>
                <div
                  style="padding: 2px"
                  class="w-50"
                >
                  <b-button
                    size="sm"
                    variant="warning"
                    class="w-100"
                    style="margin-top: 3px"
                    :disabled="!isAdmin"
                    @click="showLocationMoveScreenModal(data.item)"
                  >
                    <feather-icon icon="MonitorIcon" />
                  </b-button>
                </div>
                <div
                  class="w-50"
                  style="padding: 2px"
                >
                  <b-button
                    size="sm"
                    variant="danger"
                    class="w-100"
                    style="margin-top: 3px"
                    @click="deleteScreen(data.item)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card-text>

    <b-modal
      v-model="showDetailsModal"
      size="lg"
      hide-footer
    >
      <template #modal-title>
        {{ $t('Screen Details') }}
      </template>
      <div class="d-block">
        <b-row>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('Location Id') }} </label>
            <div> {{ shownItem.locationId }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> Sinage
              ID</label>
            <div> {{ shownItem.sinageId }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('Status') }}</label>
            <div> {{ shownItem.status }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('Owner Id') }} </label>
            <div> {{ shownItem.ownerId }} </div>
          </b-col>
          <!-- <b-col md="6" class="mb-2">
            <label class="text-secondary"><feather-icon icon="MapPinIcon" size="12" class="text-primary" /> {{ $t('Screen Manufacturer') }}</label>
            <div> {{ shownItem.screenManufacturer }} </div>
          </b-col> -->
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('Last Update') }}</label>
            <div> {{ shownItem.lastUpdate }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('Last PowerOn') }}</label>
            <div> {{ shownItem.lastPowerOn }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('Allow Default') }}</label>
            <div> {{ shownItem.allowDefault }} </div>
          </b-col>
          <!-- <b-col md="6" class="mb-2">
            <label class="text-secondary"><feather-icon icon="MapPinIcon" size="12" class="text-primary" /> {{ $t('Viewr Per Hour') }}</label>
            <div> {{ shownItem.viewerPerHour }}</div>
          </b-col> -->
          <!-- <b-col md="6" class="mb-2">
            <label class="text-secondary"><feather-icon icon="MapPinIcon" size="12" class="text-primary" /> {{ $t('Screen Model') }} </label>
            <div> {{ shownItem.screenModel }} </div>
          </b-col> -->
          <b-col md="6">
            <label><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('maxAllowedAlloc') }}</label>
            <div> {{ shownItem.maxAllowedAllocation }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('Screen Size') }} </label>
            <div> {{ shownItem.screenSize }} </div>
          </b-col>
          <!-- <b-col md="6" class="mb-2">
            <label class="text-secondary"><feather-icon icon="MapPinIcon" size="12" class="text-primary" /> {{ $t('Price') }}</label>
            <div> {{ shownItem.price }} </div>
          </b-col> -->
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('Title In Arabic') }} </label>
            <div> {{ shownItem.titleAr }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('Title In English') }}</label>
            <div> {{ shownItem.titleEn }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('Screen Serial') }} </label>
            <div> {{ shownItem.screenSerial }} </div>
          </b-col>
        </b-row>
      </div>
      <b-button
        class="mt-3"
        block
        @click="showDetailsModal = false"
      >{{ $t('close') }}</b-button>
    </b-modal>

    <b-modal
      id="moveScreenModal"
      ref="moveScreenModal"
      v-model="locationMoveScreenModal"
      size="md"
      hide-footer
      hide-header
      centered
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <span @click="close()">
          <br>
        </span>
      </template>
      <validation-observer
        ref="observer"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(doMoveLocationScreens)">
          <div class="d-block">
            <b-row class="d-flex align-items-center justify-content-between">
              <b-col
                md="6"
                class="mb-2"
              >
                <label>{{ $t("ToLocation") }}</label>
                <validation-provider
                  v-slot="validationContext"
                  :name="$t('ToLocation')"
                  :rules="{ required: true }"
                >
                  <b-form-select
                    id="toLocationIdSelection"
                    v-model="toLocationId"
                    name="toLocationIdSelection"
                    size="md"
                    :state="getValidationState(validationContext)"
                    @change="selectToLocation($event)"
                  >
                    <option
                      selected
                      :value="null"
                    >
                      {{ $t("Please select an option") }}
                    </option>
                    <option
                      v-for="option in moveScreensInfo.filterLocations"
                      :key="option.locationId"
                      :value="option"
                    >
                      {{ option.locationId }}
                    </option>
                  </b-form-select>

                  <b-form-invalid-feedback id="input-2234-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            <!-- ** link to partners start -->
            <!-- link to partners end ** -->
            </b-row>
          </div>
          <b-col class="mb-2">
            <b-button
              class="mx-1"
              type="submit"
              variant="primary"
              :disabled="moveScreensInfo.fromLocationId === null || moveScreensInfo.toLocationId === null || moveScreensInfo.screenSerial === null"
            >
              {{ $t("MoveLocationScreens") }}
            </b-button>
            <b-button @click="closeMoveScreenModal()">
              {{ $t("close") }}
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>


    <!-- add Location Setup Modal -->
    <b-modal
      id="locationSetupId"
      v-model="locationSetupModal"
      size="lg"
      hide-footer
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <span @click="close()">
          <br>

        </span>

      </template>
      <validation-observer
        ref="observer"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(submitData)">
          <b-row class="d-flex align-items-center justify-content-between">

            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <label>{{ $t('Username') }}</label>
              <validation-provider
                v-slot="validationContext"
                :name="$t('Username')"
                :rules="{ required: true }"
              >
                <b-form-select
                  id="username"
                  v-model="locationItems.username"
                  name="username"
                  size="sm"
                  :state="getValidationState(validationContext)"
                  @change="selectUsername($event)"
                >
                  <option
                    selected
                    :value="null"
                  >
                    {{ $t('Please select an option') }}
                  </option>
                  <option
                    v-for="option in activatePartners"
                    :key="option.id"
                    :value="option"
                  >
                    {{ option.username }}
                  </option>
                </b-form-select>

                <b-form-invalid-feedback id="input-2234-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>

              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >

              <validation-provider
                v-slot="validationContext"
                :name="$t('Partner Id')"
                :rules="{ required: true, alpha_num: true, min: 4 }"
              >
                <label>{{ $t('Partner Id') }}</label>
                <b-form-input
                  id="example-input-1"
                  v-model="locationItems.partnerId"
                  name="example-input-1"
                  size="sm"
                  :placeholder="$t('Partner Id')"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >

              <validation-provider
                v-slot="validationContext"
                :name="$t('Location Id')"
                :rules="{ required: true, alpha_num: true, min: 4 }"
              >
                <label>{{ $t('Location Id') }}</label>
                <b-form-input
                  id="example-input-1"
                  v-model="locationItems.locationId"
                  name="example-input-1"
                  size="sm"
                  :placeholder="$t('Location Id')"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <validation-provider
                v-slot="validationContext"
                :name="$t('Title In Arabic')"
                :rules="{ required: true, alpha_num: true, min: 4 }"
              >
                <label>{{ $t('Title In Arabic') }}</label>
                <b-form-input
                  id="example-input-2"
                  v-model="locationItems.titleAr"
                  name="example-input-2"
                  size="sm"
                  :placeholder="$t('Title In Arabic')"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-2-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >

              <validation-provider
                v-slot="validationContext"
                :name="$t('Title In English')"
                :rules="{ required: true, alpha_num: true, min: 4 }"
              >
                <label>{{ $t('Title In English') }}</label>
                <b-form-input
                  id="example-input-2"
                  v-model="locationItems.titleEn"
                  name="example-input-2"
                  size="sm"
                  :placeholder="$t('Title In English')"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-2-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >

              <validation-provider
                v-slot="validationContext"
                :name="$t('Viewr Per Hour')"
                :rules="{ required: true, numeric: true, min: 1 }"
              >
                <label>{{ $t('Viewr Per Hour') }}</label>
                <b-form-input
                  id="viewerPerHour"
                  v-model="locationItems.viewerPerHour"
                  name="viewerPerHour"
                  size="sm"
                  :placeholder="$t('Viewr Per Hour')"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-2-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >

              <validation-provider
                v-slot="validationContext"
                :name="$t('Screen Serial')"
                :rules="{ required: true, alpha_num: true, min: 2 }"
              >
                <label>{{ $t('Screen Serial') }}</label>
                <b-form-input
                  id="ScreenSerial"
                  v-model="locationItems.screenSerial"
                  name="ScreenSerial"
                  size="sm"
                  :placeholder="$t('Screen Serial')"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >

              <validation-provider
                v-slot="validationContext"
                :name="$t('Screen Manufacturer')"
                :rules="{ required: true, alpha: true, min: 2 }"
              >
                <label>{{ $t('Screen Manufacturer') }}</label>
                <b-form-input
                  id="screenManufacturer"
                  v-model="locationItems.screenManufacturer"
                  name="screenManufacturer"
                  size="sm"
                  :placeholder="$t('Screen Manufacturer')"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >

              <validation-provider
                v-slot="validationContext"
                :name="$t('Screen Size')"
                :rules="{ required: true }"
              >
                <label>{{ $t('Screen Size') }}</label>
                <b-form-select
                  id="screenSize"
                  v-model="locationItems.screenSize"
                  name="screenSize"
                  size="sm"
                  :state="getValidationState(validationContext)"
                >
                  <option
                    selected
                    :value="null"
                  >
                    {{ $t('Please select an option') }}
                  </option>
                  <option
                    v-for="option in screenSizeList"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.value }}
                  </option>
                </b-form-select>

                <b-form-invalid-feedback id="input-6654-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>

              </validation-provider>
            </b-col>

            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >

              <validation-provider
                v-slot="validationContext"
                name="Signage Id"
                :rules="{ required: true, alpha_num: true, min: 2 }"
              >
                <label>Signage Id</label>
                <b-form-input
                  id="signageId"
                  v-model="locationItems.signageId"
                  name="signageId"
                  size="sm"
                  placeholder="Enter Signage Id"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >

              <validation-provider
                v-slot="validationContext"
                :name="$t('Device Type')"
                :rules="{ required: true, alpha: true, min: 3 }"
              >
                <label>{{ $t('Device Type') }}</label>
                <b-form-input
                  id="deviceType"
                  v-model="locationItems.deviceType"
                  name="deviceType"
                  size="sm"
                  :placeholder="$t('Device Type')"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >

              <validation-provider
                v-slot="validationContext"
                :name="$t('Default Price')"
                :rules="{ required: true, min: 1, min_value: 1 }"
              >
                <label>{{ $t('Default Price') }}</label>
                <b-form-input
                  id="defaultPrice"
                  v-model="locationItems.defaultPrice"
                  name="defaultPrice"
                  size="sm"
                  :placeholder="$t('Default Price')"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback id="input-8-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <label>{{ $t('maxAllowedAlloc') }}</label>

              <validation-provider
                v-slot="validationContext"
                :name="$t('maxAllowedAlloc')"
                :rules="{ required: true }"
              >
                <b-form-select
                  id="maxAllowedAlloc"
                  v-model="locationItems.maxAllowedAlloc"
                  name="maxAllowedAlloc"
                  size="sm"
                  :state="getValidationState(validationContext)"
                >
                  <option
                    selected
                    :value="null"
                  >
                    {{ $t('Please select an option') }}
                  </option>
                  <option
                    v-for="maxAllowedAllocLabel in maxAllowedAllocList"
                    :key="maxAllowedAllocLabel.id"
                    :value="maxAllowedAllocLabel.id"
                  >
                    {{ maxAllowedAllocLabel.label }}
                  </option>
                </b-form-select>

                <b-form-invalid-feedback id="input-798-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>

              </validation-provider>
            </b-col>
            <hr>
            <!-- ** link to partners start -->

            <!-- link to partners end ** -->
          </b-row>
          <b-row>

            <b-col
              class="mb-2"
              md="4"
              sm="4"
            >

              <b-button
                class="text-capitalize mt-1"
                :ripple.400="'rgba(113, 102, 240, 0.15)'"
                type="submit"
                variant="primary"
              >
                {{ $t('Add New Location') }}
              </b-button>

            </b-col>
            <b-col
              md="4"
              sm="4"
              class="mb-2"
            >
              <b-button
                class="mt-1"
                @click="locationSetupModal = false"
              >{{ $t('close') }}</b-button>

            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BSpinner, BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/useJwt'
import store from '@/store'

export default {
  components: {
    BTable,
    BBadge,
    BRow,
    BSpinner,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      locations: [],
      activatePartners: [],
      locationMoveScreenModal: false,
      toLocationId: null,
      moveScreensInfo: {
        fromLocationId: null,
        toLocationId: null,
        filterLocations: [],
        screenSerial: null,
      },
      maxAllowedAllocList: [
        { id: 10, label: 10 },
        { id: 20, label: 20 },
        { id: 30, label: 30 },
        { id: 40, label: 40 },
        { id: 50, label: 50 },
        { id: 60, label: 60 },
      ],
      locationSetupModal: false,
      showDetailsModal: false,
      screenSizeList: [{
        id: 'L', value: this.$t('horizontal'),
      }, {
        id: 'P', value: this.$t('vertical'),
      }],
      locationItems: {
        username: null,
        partnerId: '',
        partnerIdType: null,
        locationId: '',
        viewerPerHour: '',
        screenSerial: '',
        screenManufacturer: '',
        screenSize: null,
        titleAr: '',
        titleEn: '',
        defaultPrice: '',
        screenModel: 'Other',
        signageId: '',
        deviceType: '',
        maxAllowedAlloc: null,
      },
      shownItem: {},
      queryFilter: {
        status: '', locationId: '', screenSize: '', maxAllowedAllocation: '',
      },
      setups: [],
      isLoading: false,
      perPage: 10,
      pageOptions: [10, 15, 20],
      statusOptions: [
        {
          text: this.$t('All'), value: '',
        },
        {
          text: this.$t('ShowingAdx'), value: 'ShowingAdx',
        },
        {
          text: this.$t('ShowingAdverts'), value: 'ShowingAdverts',
        },
        {
          text: this.$t('Disconnected'), value: 'Disconnected',
        },
        {
          text: this.$t('Idle'), value: 'Idle',
        },
      ],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'locationId', label: this.$t('Location Id'), sortable: true },
        { key: 'titleEn', label: this.$t('Title'), sortable: true },
        { key: 'price', label: this.$t('Price'), sortable: true },
        { key: 'status', label: this.$t('Status'), sortable: true },
        { key: 'deviceType', label: this.$t('Device Type'), sortable: false },
        { key: 'lastUpdate', label: this.$t('Last Update'), sortable: false },
        { key: 'lastPowerOn', label: this.$t('Last PowerOn'), sortable: false },
        { key: 'allowDefault', label: this.$t('Allow Default'), sortable: false },
        { key: 'actions', label: this.$t('Actions'), sortable: false },
      ],
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.app.userRole == 'Admin'
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    filteredSetups() {
      return this.applyFilter()
    },
    isRtl() {
      return store.state.appConfig.layout.isRTL
    },
  },
  watch: {
    queryFilter: {
      deep: true,
      handler() {
        this.applyFilter()
      },
    },
    isRtl: {
      deep: true,
      handler() {
        this.refreshFields()
        this.refreshScreenSizeFields()
      },

    },
  },
  created() {
    this.getAllLocations()
    this.getScreens()
    this.getPartners()
  },

  mounted() {
    // Set the initial number of items
    this.queryFilter = { ...this.$route.query }

    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'locationSetupId') {
        this.locationItems = {
          username: null,
          partnerId: '',
          partnerIdType: null,
          locationId: '',
          viewerPerHour: '',
          screenSerial: '',
          screenManufacturer: '',
          screenSize: null,
          titleAr: '',
          titleEn: '',
          lastPowerOn: '',
          lastUpdate: '',
          allowDefault: '',
          defaultPrice: '',
          screenModel: 'Other',
          signageId: '',
          deviceType: '',
          maxAllowedAlloc: null,
        }
      }
    })
  },
  methods: {
    closeMoveScreenModal() {
      console.log('closeMoveScreenModal')
      this.toLocationId = null
      this.moveScreensInfo.fromLocationId = null
      this.moveScreensInfo.toLocationId = null
      this.moveScreensInfo.filterLocations = []
      this.locationMoveScreenModal = false
    },
    selectToLocation(value) {
      console.log(this.moveScreensInfo)
      if (value !== null) {
        console.log(value)
        this.moveScreensInfo.toLocationId = value.locationId
      }
    },
    showLocationMoveScreenModal(screen) {
      console.log(screen)
      console.log(this.setups)
      this.moveScreensInfo.filterLocations = this.locations.filter(
        l => l.locationId !== screen.locationId,
      )
      console.log(this.moveScreensInfo.filterLocations)
      this.moveScreensInfo.fromLocationId = screen.locationId
      this.moveScreensInfo.screenSerial = screen.screenSerial
      this.locationMoveScreenModal = true
      console.log(this.moveScreensInfo)
    },
    async doMoveLocationScreens() {
      console.log(this.moveScreensInfo)
      const data = {
        locationId: this.moveScreensInfo.fromLocationId,
        toLocationId: this.moveScreensInfo.toLocationId,
        screenSerial: this.moveScreensInfo.screenSerial,
      }
      console.log(data)
      await this.$swal({
        title: this.$t('Are you sure'),
        text: this.$t('You wont be able to revert this'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          useJwt
            .post('/asset/api/domain/locations/move/screen', data)
            .then(res => {
              console.log(res)
              this.$swal({
                icon: 'success',
                title: this.$t('Success'),
                text: this.$t('Screens moved successfully'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              const { screens } = this.setups.find(setup => setup.locationInfo.locationId === this.moveScreensInfo.fromLocationId).locationInfo
              console.log(screens)
              this.setups = [...this.setups.map(setup => {
                console.log(setup)
                if (setup.locationInfo.locationId === this.moveScreensInfo.fromLocationId) {
                  return { ...setup, locationInfo: { ...setup.locationInfo, screens: [...setup.locationInfo.screens.filter(s => s.screenSerial !== data.screenSerial)] } }
                }
                if (setup.locationInfo.locationId === this.moveScreensInfo.toLocationId) {
                  return { ...setup, locationInfo: { ...setup.locationInfo, screens: [...setup.locationInfo.screens, data.screenSerial] } }
                }
                return setup
              })]
              this.closeMoveScreenModal()
            })
            .catch(err => {
              console.log(err)
              this.$swal({
                icon: 'error',
                title: this.$t('Oops'),
                text: this.$t(`Something went wrong: ${err}`),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    selectUsername(value) {
      this.locationItems.partnerIdType = value.partnerIdType
    },
    submitData() {
      console.log(this.locationItems.partnerIdType, 'this.locationItems.partnerIdType')
      this.locationItems.viewerPerHour = Number(this.locationItems.viewerPerHour)
      this.locationItems.defaultPrice = Number(this.locationItems.defaultPrice)
      this.locationItems.maxAllowedAlloc = Number(this.locationItems.maxAllowedAlloc)
      this.locationItems.screenSize = `${this.locationItems.screenSize}_55Inch`
      useJwt.post('/asset/api/domain/location/setup', {
        ...this.locationItems,
      })
        .then(({ data }) => {
          console.log(data)
          this.modalShow = false
          this.$swal({
            icon: 'success',
            title: this.$t('Success'),
            text: this.$t('The Location Info added successfully'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            title: this.$t('Oops'),
            text: this.$t(`Something went wrong: ${err}`),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    applyFilter() {
      let { setups } = this
      Object.keys(this.queryFilter).forEach(filterName => {
        if (this.queryFilter[filterName] && this.queryFilter[filterName] !== '') {
          if ([filterName].find(element => element === filterName)) {
            setups = setups.filter(e => e[filterName].toString().toLowerCase().includes(this.queryFilter[filterName].toString().toLowerCase()))
          } else {
            setups = setups.filter(e => e[filterName] === this.queryFilter[filterName])
          }
        }
      })
      this.onFiltered(setups)
      return setups
    },
    showToast(data) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: Object.keys(data)[0].toString() || '',
          icon: 'BellIcon',
          text: JSON.stringify(Object.values(data)[0]) || '',
          variant: 'danger',
        },
      })
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getScreens() {
      this.isLoading = true
      useJwt.get('/qasset/api/domain/setups')
        .then(({ data }) => {
          this.setups = []
          data.data.controllers.forEach(sets => {
            console.log(sets)
            const location = sets.locationId
            sets.controllers.forEach(setups => {
              this.setups.push({
                locationId: location,
                sinageId: setups.sinageId,
                price: setups.price,
                ownerIdType: setups.ownerIdType,
                titleAr: setups.titleAr,
                screenManufacturer: setups.screenManufacturer,
                status: setups.status,
                ownerId: setups.ownerId,
                viewerPerHour: setups.viewerPerHour,
                titleEn: setups.titleEn,
                lastPowerOn: setups.lastPowerOn,
                lastUpdate: setups.lastUpdate,
                screenModel: setups.screenModel,
                allowDefault: setups.allowDefault,
                maxAllowedAllocation: setups.maxAllowedAllocation,
                screenSize: setups.screenSize,
                screenSerial: setups.screenSerial,
                deviceType: setups.deviceType,
              })
            })
          })
          this.totalRows = this.setups.length
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
          return false
        })
    },
    deleteScreen(screen) {
      this.$swal({
        title: this.$t('Are you sure'),
        text: this.$t('You wont be able to revert this'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          console.log(screen.sinageId)
          useJwt.delete(`/asset/api/domain/location/${screen.locationId}/setups/${screen.sinageId}`)
            .then(res => {
              console.log(res)
              this.setups = [...this.setups.filter(s => s.sinageId !== screen.sinageId)]
              this.$swal({
                icon: 'success',
                title: this.$t('Deleted'),
                text: this.$t('Screen has been Deleted'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }).catch(err => {
              this.$swal({
                icon: 'error',
                title: this.$t('Oops'),
                text: this.$t(`Something went wrong: ${err}`),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    showDetail(object) {
      this.shownItem = { ...object }
      this.showDetailsModal = true
    },
    showLocationSetupModal() {
      this.locationSetupModal = true
    },
    getPartners() {
      this.isLoading = true;

      if(this.isAdmin) {
        useJwt
          .get("/qpartner/api/domain/partners")
          .then(({ data }) => {
            this.activatePartners = data.data.activatePartners;
          })
          .catch((err) => {
            console.error();
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.data = JSON.parse(localStorage.getItem('userInfo'))
        this.activatePartners = [{ username: this.data.partnerInfo.data.username, partnerIdType: 'CompanyId', partnerInfo: this.data.partnerInfo }]
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    refreshFields() {
      this.fields = [
        { key: 'locationId', label: this.$t('Location Id'), sortable: true },
        { key: 'titleEn', label: this.$t('Title'), sortable: true },
        { key: 'price', label: this.$t('Price'), sortable: true },
        { key: 'status', label: this.$t('Status'), sortable: true },
        { key: 'deviceType', label: this.$t('Device Type'), sortable: false },
        { key: 'lastUpdate', label: this.$t('Last Update'), sortable: false },
      ]
    },
    refreshScreenSizeFields() {
      this.screenSizeList = [{
        id: 'L', value: this.$t('horizontal'),
      }, {
        id: 'P', value: this.$t('vertical'),
      }]
    },
    getAllLocations() {
      this.isLoading = true
      useJwt
        .get('/qasset/api/domain/locations')
        .then(({ data }) => {
          this.locations = data.data.locations
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 400px;
  }
}
</style>
